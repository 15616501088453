<template lang="pug">
PricingGuard(:min_level="3")
  Form(ref="form")
  ItemForm(ref="item")
  .s-vstack(v-if="rsvtsetting")
    SettingView(:setting="rsvtsetting", @edit="edit")
    .d-flex.flex-row.align-center
      h2 Bookable Items
      v-spacer
      IconBtn(
        @click="add()",
        color="secondary",
        title="Bookable Item",
        icon="mdi-plus",
        :outlined="false"
      )
    v-row
      v-col(
        cols="12",
        md="6",
        v-for="(item, key) in rsvtsetting.items",
        :key="key"
      )
        Cell(
          :item="item",
          :setting="rsvtsetting",
          @edit="() => $refs.item.open(rsvtsetting, item)",
          @delete="handleDelete(item)"
        ) 
    RsvtOption
    BookedUp
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Cell from "./Cell";
import Form from "./Form";
import ItemForm from "./ItemForm";
import SettingView from "./SettingView";
import BookedUp from "/libs/components/Booking/BookedUp";
import RsvtOption from "/libs/components/Booking/RsvtOption";

export default {
  components: {
    Form,
    ItemForm,
    Cell,
    RsvtOption,
    BookedUp,
    SettingView,
  },
  computed: {
    ...mapGetters(["biz", "rsvtsetting"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  methods: {
    edit() {
      this.$refs.form.open(this.rsvtsetting);
    },
    add() {
      this.$refs.item.open(this.rsvtsetting);
    },
    async load() {
      if (!this.biz?._id) return;
      const criteria = { _id: this.biz._id };
      try {
        const result = await this.$api.b.rsvtsetting.retrieve({ criteria });
        this.$store.dispatch("setRsvtSetting", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async handleDelete(item) {
      if (!this.rsvtsetting?._id || !item?._id) return;
      const criteria = { _id: this.rsvtsetting._id };
      const action = { $pull: { items: { _id: item._id } } };
      try {
        const result = await this.$api.b.rsvtsetting.update({
          criteria,
          action,
        });
        this.$store.dispatch("setRsvtSetting", result);
        this.$toast.success("Deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>